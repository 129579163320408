import env from '../env'

export const LINK_URL_ROOT = '/'
export const LINK_URL_LOGIN = '/login'
export const LINK_URL_CANDIDATE_HOME = '/jobs'
export const LINK_URL_TOKEN_NOT_FOUND = '/token-not-found'
export const LINK_URL_EMAIL_SENT = '/forgot-password/confirmation'
export const LINK_URL_COMPANIES = '/companies'
export const LINK_URL_FOOTER_COMPANIES = `${env.PUBLIC_APP_DOMAIN}/companies`
export const LINK_URL_TERMS = env.PUBLIC_LINK_URL_TERMS || '/terms-of-service'
export const LINK_URL_POLICY = env.PUBLIC_LINK_URL_POLICY || '/privacy-policy'
export const LINK_URL_FORGOT_PASSWORD = '/forgot-password'
export const LINK_URL_JOBS = '/jobs'
export const LINK_FOOTER_JOBS = `${env.PUBLIC_APP_DOMAIN}/jobs`
export const LINK_URL_LP_HEADHUNT_SERVICE = '/employer/headhunt-service'
export const LINK_URL_FOOTER_LP_HEADHUNT_SERVICE = `${env.PUBLIC_APP_DOMAIN}/employer/headhunt-service`
export const LINK_URL_EMPLOYER_PRODUCT = '/employer/product'
export const LINK_URL_REGISTER = `${env.PUBLIC_EMPLOYER_APP_DOMAIN}/register`
export const LINK_URL_CANDIDATE_REGISTER = '/register'
export const LINK_URL_EMPLOYER = env.PUBLIC_EMPLOYER_APP_DOMAIN
export const LINK_URL_CANDIDATES_CHANGE_PASSWORD = '/account/change-password'
export const LINK_URL_CANDIDATES_MANAGE_YOUR_CV = '/account/manage-cv'
export const LINK_URL_CANDIDATES_JOB_ALERT = '/account/job-alert'
export const LINK_URL_MY_ACCOUNT = '/account/your-account'
export const LINK_URL_CANDIDATES_MY_PROFILE = '/my-profile'
export const LINK_URL_CANDIDATES_CV = '/profile'
export const LINK_URL_CV = '/cv'
export const LINK_URL_CANDIDATES_EDIT_CV_ONLINE = '/profile/edit'
export const LINK_URL_CHAT_WITH_SUPPORT = '/chat-with-support'
export const LINK_URL_EMPLOYER_CONTACT = `${env.PUBLIC_EMPLOYER_APP_DOMAIN}/contact`
export const LINK_URL_LP_CV_ONLINE = '/cv-online'
export const LINK_URL_FOOTER_LP_CV_ONLINE = `${env.PUBLIC_APP_DOMAIN}/cv-online`

export function createNewCompanyIdSlug(slug: string, id: number | string): string {
  return `${slug}-fi${id}`
}
export const LINK_URL_COMPANY_PUBLISH_JOBS = (company_slug: string, company_id: number) =>
  '/company/[slug]'.replace('[slug]', createNewCompanyIdSlug(company_slug, company_id))
export function createIdSlug(slug: string, id: number | string): string {
  return `${slug}-i${id}`
}
export const LINK_URL_JOB_DETAILS = (slug: string, id: number | string) => '/jobs/[slug]'.replace('[slug]', createIdSlug(slug, id))
export const LINK_URL_JOB_DETAILS_SUCCESSFUL = (slug: string, id: number | string) =>
  '/jobs/[slug]'.replace('[slug]', createIdSlug(slug, id)) + '/successful'

export const LINK_URL_DOWNLOAD_APPLE = 'https://apps.apple.com/us/app/freec/id1475879992?l=vi&ls=1'
export const LINK_URL_DOWNLOAD_ANDROID = 'https://play.google.com/store/apps/details?id=com.freec.asia'
export const LINK_URL_ONBOARDING_CANDIDATE = '/onboarding'
export const LINK_URL_FORGOT_VERIFY_TOKEN = '/forgot-password/reset-password'
export const LINK_URL_PUBLISH_CV = (id: number | string) => `${env.PUBLIC_APP_DOMAIN}/cv/${id}`
export const LINK_URL_TEMPLATES_LISTING = `/resume-templates`
export const LINK_URL_CV_EXAMPLES = `/cv-examples`
