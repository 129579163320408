import { useAmp } from 'next/amp'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import useAppRouter from '../../hooks/useAppRouter'
import useCurrentUser from '../../hooks/useCurrentUser'
import useIsLogin from '../../hooks/useIsLogin'
import { useTranslation } from '../../hooks/useTranslation'
import {
  LINK_URL_CANDIDATES_CV,
  LINK_URL_COMPANIES,
  LINK_URL_EMPLOYER,
  LINK_URL_GROSS_NET,
  LINK_URL_JOBS,
  LINK_URL_LOGIN,
  LINK_URL_LP_CV_ONLINE,
  LINK_URL_MY_JOBS_APPLIED,
  LINK_URL_MY_JOBS_SAVED,
  LINK_URL_CANDIDATE_REGISTER,
  LINK_URL_SEARCH
} from '../../utillities/LinkURL'
import ChangeLanguageDropdown from '../ChangeLanguageDropdown'
import HeaderProfile from '../HeaderProfile'
import LinkWrapper from '../Link'
import Sidebar from '../Sidebar'
import ICON_ARROW_RIGHT_BLUE from './assets/arrow-right-blue.svg'
import ICON_BUILDINGS from './assets/buildings.svg'
import LOGO_FREEC_BLUE from './assets/freec-logo-blue.svg'
import NotificationModal from '../../../../freec_apps/public/modules/notifications/components/NotificationModal'
// import DownOutlined from './assets/DownOutlined'
// import { LINK_URL_TEMPLATES_LISTING, LINK_URL_CV_EXAMPLES } from '../../utillities/URL'
const DesktopHeader = ({ NotificationComponents, visible, toggleNotification }) => {
  const router = useAppRouter()
  const isAmp = useAmp()
  const { translate } = useTranslation()
  const [user] = useCurrentUser()

  const isLogin = useIsLogin()
  const isShadow =
    [LINK_URL_MY_JOBS_APPLIED, LINK_URL_MY_JOBS_SAVED, LINK_URL_COMPANIES, LINK_URL_SEARCH].includes(router.pathname) ||
    router.pathname.indexOf(LINK_URL_JOBS) > -1
  const [isHeaderVisible, setIsHeaderVisible] = useState(false)

  useEffect(() => {
    if (!isLogin || (isLogin && user)) {
      setIsHeaderVisible(true)
    }
  }, [user])

  // const renderDropdownNotLogin = () => {
  //   return (
  //     <div className="d-flex dropdown-language">
  //       <label className="box-dropdown">
  //         <LinkWrapper prefetch={false} href={LINK_URL_LP_CV_ONLINE} as={LINK_URL_LP_CV_ONLINE}>
  //           <a className={`header-link ${router.pathname === LINK_URL_LP_CV_ONLINE ? 'active' : ''}`}>
  //             Profile & CV <DownOutlined /> <span className="tag-beta">new</span>
  //           </a>
  //         </LinkWrapper>
  //       </label>

  //       <div className="sub-dropdown" style={{ right: 'unset', left: 0 }}>
  //         <div className="box">
  //           <div>
  //             <a className="sub-link" target="_blank" rel="noopener noreferrer" href={LINK_URL_TEMPLATES_LISTING}>
  //               CV Templates
  //             </a>
  //           </div>
  //           <div>
  //             <a className="sub-link" target="_blank" rel="noopener noreferrer" href={LINK_URL_CV_EXAMPLES}>
  //               CV Examples
  //             </a>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  // const renderDropdownIsLogin = () => {
  //   return (
  //     <div className="d-flex dropdown-language">
  //       <label className="box-dropdown">
  //         <LinkWrapper prefetch={false} href={LINK_URL_CANDIDATES_CV} as={LINK_URL_CANDIDATES_CV}>
  //           <a className={`header-link ${router.pathname === LINK_URL_CANDIDATES_CV ? 'active' : ''}`}>
  //             Profile & CV <DownOutlined /> <span className="tag-beta">new</span>
  //           </a>
  //         </LinkWrapper>
  //       </label>

  //       <div className="sub-dropdown" style={{ right: 'unset', left: 0 }}>
  //         <div className="box">
  //           <div>
  //             <a className="sub-link" target="_blank" rel="noopener noreferrer" href={LINK_URL_TEMPLATES_LISTING}>
  //               CV Templates
  //             </a>
  //           </div>
  //           <div>
  //             <a className="sub-link" target="_blank" rel="noopener noreferrer" href={LINK_URL_CV_EXAMPLES}>
  //               CV Examples
  //             </a>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <div className={`header header-hide-mobile ${isShadow ? 'none-shadow' : ''} ${isHeaderVisible && 'header-visit'}`}>
      <div className="left-group">
        <LinkWrapper prefetch={false} href={isLogin ? LINK_URL_JOBS : '/home'} as={isLogin ? LINK_URL_JOBS : '/home'}>
          <a className="header-logo-img">
            <div className="logo-freec">
              {isAmp === true ? (
                <div style={{ width: 51, height: 34, margin: 'auto' }}>
                  <amp-img width={51} height={34} src={LOGO_FREEC_BLUE?.src} alt="freec-platform" layout="responsive" />
                </div>
              ) : (
                <Image src={LOGO_FREEC_BLUE} alt="freec-platform" width={51} height={34} />
              )}
            </div>
          </a>
        </LinkWrapper>

        <div className="header-link-group">
          <LinkWrapper hardReload prefetch={false} href={LINK_URL_JOBS} as={LINK_URL_JOBS}>
            <a className={`header-link ${router.pathname === LINK_URL_JOBS ? 'active' : ''}`}>{translate('header:find_jobs')}</a>
          </LinkWrapper>
          {isHeaderVisible &&
            (isLogin ? (
              <>
                <LinkWrapper prefetch={false} href={LINK_URL_MY_JOBS_APPLIED} as={LINK_URL_MY_JOBS_APPLIED}>
                  <a
                    className={`header-link ${
                      [LINK_URL_MY_JOBS_APPLIED, LINK_URL_MY_JOBS_SAVED].includes(router.pathname) ? 'active' : ''
                    }`}>
                    {translate('header:my_jobs')}
                  </a>
                </LinkWrapper>

                <LinkWrapper prefetch={false} href={LINK_URL_CANDIDATES_CV} as={LINK_URL_CANDIDATES_CV}>
                  <a className={`header-link ${router.pathname == LINK_URL_CANDIDATES_CV ? 'active' : ''}`}>
                    {translate('header:profile_cv')} <span className="tag-beta">new</span>
                  </a>
                </LinkWrapper>
                {/* {!isAmp ? (
                  renderDropdownIsLogin()
                ) : (
                  <LinkWrapper prefetch={false} href={LINK_URL_CANDIDATES_CV} as={LINK_URL_CANDIDATES_CV}>
                    <a className={`header-link ${router.pathname == LINK_URL_CANDIDATES_CV ? 'active' : ''}`}>
                      {translate('header:profile_cv')} <span className="tag-beta">new</span>
                    </a>
                  </LinkWrapper>
                )} */}

                <LinkWrapper prefetch={false} href={LINK_URL_GROSS_NET} as={LINK_URL_GROSS_NET}>
                  <a className={`header-link ${router.pathname === LINK_URL_GROSS_NET ? 'active' : ''}`}>{translate('header:gross_net')}</a>
                </LinkWrapper>

                <LinkWrapper hardReload prefetch={false} href={LINK_URL_COMPANIES} as={LINK_URL_COMPANIES}>
                  <a className={`header-link ${router.pathname == LINK_URL_COMPANIES ? 'active' : ''}`}>{translate('header:companies')}</a>
                </LinkWrapper>
              </>
            ) : (
              <>
                <LinkWrapper prefetch={false} href={LINK_URL_LP_CV_ONLINE} as={LINK_URL_LP_CV_ONLINE}>
                  <a className={`header-link ${router.pathname === LINK_URL_LP_CV_ONLINE ? 'active' : ''}`}>
                    {translate('header:cv_online')} <span className="tag-beta">new</span>
                  </a>
                </LinkWrapper>
                {/* {!isAmp ? (
                  renderDropdownNotLogin()
                ) : (
                  <LinkWrapper prefetch={false} href={LINK_URL_LP_CV_ONLINE} as={LINK_URL_LP_CV_ONLINE}>
                    <a className={`header-link ${router.pathname === LINK_URL_LP_CV_ONLINE ? 'active' : ''}`}>
                      {translate('header:cv_online')} <span className="tag-beta">new</span>
                    </a>
                  </LinkWrapper>
                )} */}

                <LinkWrapper prefetch={false} href={LINK_URL_GROSS_NET} as={LINK_URL_GROSS_NET}>
                  <a className={`header-link ${router.pathname == LINK_URL_GROSS_NET ? 'active' : ''}`}>{translate('header:gross_net')}</a>
                </LinkWrapper>
                <LinkWrapper hardReload prefetch={false} href={LINK_URL_COMPANIES} as={LINK_URL_COMPANIES}>
                  <a className={`header-link ${router.pathname == LINK_URL_COMPANIES ? 'active' : ''}`}>{translate('header:companies')}</a>
                </LinkWrapper>
              </>
            ))}
        </div>
      </div>

      {!isAmp && !isLogin && (
        <div className="right-group">
          <div className="header-sign-in-up">
            <LinkWrapper prefetch={false} href={LINK_URL_LOGIN} as={LINK_URL_LOGIN}>
              <a className="btn-login"> {translate('header:login')}</a>
            </LinkWrapper>
            <LinkWrapper prefetch={false} href={LINK_URL_CANDIDATE_REGISTER} as={LINK_URL_CANDIDATE_REGISTER}>
              <a className="btn-register">{translate('header:register')}</a>
            </LinkWrapper>
          </div>
          <ChangeLanguageDropdown />
          <div className="header-employer">
            <LinkWrapper prefetch={false} href={LINK_URL_EMPLOYER} as={LINK_URL_EMPLOYER}>
              <a className="btn row align-items-center">
                <div className="icon-left d-flex align-items-center justify-content-center">
                  <Image src={ICON_BUILDINGS} alt="freec-platform" width={40} height={40} />
                </div>
                <div className="btn-info">
                  <div className="title">{translate('header:for_employers')}</div>
                  <div className="sub-title">{translate('header:btn_employer_sub')}</div>
                </div>
                <div className="icon-right">
                  <Image src={ICON_ARROW_RIGHT_BLUE} alt="freec-platform" width={24} height={24} />
                </div>
              </a>
            </LinkWrapper>
          </div>
        </div>
      )}
      {!!user && NotificationModal && <NotificationModal />}
      {!isAmp && !!user && (
        <div className="right-group">
          {NotificationComponents?.ChatCtxHeader ? (
            <div style={{ marginLeft: 24 }}>
              <NotificationComponents.ChatCtxHeader />
            </div>
          ) : null}
          {NotificationComponents?.NotificationBell ? (
            <NotificationComponents.NotificationBell toggleNotification={toggleNotification} />
          ) : null}
          {NotificationComponents?.NotificationWrapper ? (
            <NotificationComponents.NotificationWrapper open={visible} onClose={toggleNotification} />
          ) : null}
          {NotificationComponents?.EnrichModal ? <NotificationComponents.EnrichModal /> : null}
          <div className="header-profile">
            <HeaderProfile router={router} />
          </div>
          <ChangeLanguageDropdown subDropdownProps={{ style: { right: 0, left: 'unset' } }} />
        </div>
      )}
    </div>
  )
}

const Header = ({ NotificationComponents }) => {
  // if (window.innerWidth <= 1023.98 || window.outerWidth <= 1023.98) {
  //   return <Sidebar isClient={isClient} />
  // }
  const [visible, setVisible] = useState(false)
  const toggleNotification = () => {
    if (!visible) {
      document.querySelector('body').style['overflow-y'] = 'hidden'
    } else {
      document.querySelector('body').style['overflow-y'] = 'auto'
    }
    setVisible(!visible)
  }
  return (
    <>
      <DesktopHeader NotificationComponents={NotificationComponents} visible={visible} toggleNotification={toggleNotification} />
      <Sidebar NotificationComponents={NotificationComponents} toggleNotification={toggleNotification} />
    </>
  )
}

export default Header
